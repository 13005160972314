import React from 'react';

import { ItemBlockProps } from 'models/blocks';
import { ItemImage } from '../ItemImage/ItemImage';

export const Project: React.FC<ItemBlockProps> = (props: ItemBlockProps) => {

  return (
    <div className='w-full md:w-1/3 p-2 text-center'>
      <ItemImage {...props.image} alt={`${props.name} icon`} />
      <h3 className='capitalize text-xl uppercase leading-tight text-gray font-header font-bold my-2'>
        {props.name}
      </h3>
      <p className='text-gray-light'>{props.itemDescription?.content}</p>
    </div>
  );
};