import React from 'react';
import { graphql } from 'gatsby';

import {
  ContentBlockProps, HeroBlockProps, CopyBlockProps,
  ServicesBlockProps, ProjectsBlockProps
} from 'models/blocks';

import { Hero } from './Hero/Hero';
import { Copy } from './Copy/Copy';
import { Services } from './Services/Services';
import { Projects } from './Projects/Projects';

enum BlockType {
  HeroBlock = 'ContentfulBlockHero',
  CopyBlock = 'ContentfulBlockCopy',
  ServicesBlock = 'ContentfulBlockServices',
  ProjectsBlock = 'ContentfulBlockProjects',
}

export const Block: React.FC<ContentBlockProps> = (props: ContentBlockProps) => {
  const blockType = props.type;

  switch (blockType) {
    case BlockType.HeroBlock:
      const heroProps = props as HeroBlockProps;
      return (<Hero {...heroProps} />);
    case BlockType.CopyBlock:
      const copyProps = props as CopyBlockProps;
      return (<Copy {...copyProps} />);
    case BlockType.ServicesBlock:
      const servicesProps = props as ServicesBlockProps;
      return <Services {...servicesProps} />
    case BlockType.ProjectsBlock:
      const projectsProps = props as ProjectsBlockProps;
      return <Projects {...projectsProps} />
    default:
      return null;
  }
};

export const contentBlockFields = graphql`
  fragment ContentBlockFields on Node {
    ... on Node {
      id
      type: __typename
      ... on ContentfulBlockHero {
        ...BlockHeroFields
      }
      ... on ContentfulBlockCopy {
        ...BlockCopyFields
      }
      ... on ContentfulBlockItem {
        ...BlockItemFields
      }
      ... on ContentfulBlockServices {
        ...BlockServicesFields
      }
      ... on ContentfulBlockProjects {
        ...BlockProjectsFields
      }
    }
  }
`;
