import React from 'react';
import Img from 'gatsby-image';

const DEFAULT_ICON_WIDTH = 75;
const DEFAULT_ICON_HEIGHT = 75;

interface Props {
  svg?: any;
  file?: any;
  fluid?: any;
  alt: string;
}

export const ItemImage: React.FC<Props> = (props: Props) => {
  const itemImage = props.fluid;

  // render inline SVG with fallback to non-svg images
  if (props.file && props.file.contentType === 'image/svg+xml') {
    if (props.svg && props.svg.content) {
      // inline SVG
      return (
        <div className='mx-auto mb-2' style={{ width: '75px' }} dangerouslySetInnerHTML={{ __html: props.svg.content }} />
      );
    }
    // SVGs that cannot/should not be inlined
    const url = props.file.url || undefined;
    const width = props.file.details?.image?.width || DEFAULT_ICON_WIDTH;
    const height = props.file.details?.image?.height || DEFAULT_ICON_HEIGHT;
    return (
      <img src={url} alt={props.alt} width={width} height={height} className='mx-auto mb-2' />
    );
  }

  return (
    <Img fluid={itemImage} alt={props.alt} />
  );
}
