import React from 'react';
import { graphql } from 'gatsby';

import { ContentBlockProps } from 'models/blocks';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Block } from '../components/Blocks';

import { IndexPageQueryQuery } from '../../types/graphql-types';

type Props = {
  data: IndexPageQueryQuery;
}

const IndexPage: React.FC<Props> = ({ data }: Props) => {
  const pageBlocks = data.allContentfulPage.edges
    .reduce((blocks, edge) => {
      if (edge.node.blocks) {
        edge.node.blocks.forEach(block => {
          if (block) {
            blocks.push(block);
          }
        });
      }
      return blocks;
    }, [] as ContentBlockProps[]);

  return (
    <Layout>
      <SEO title="Home" />
      {
        pageBlocks.map(block => <Block key={block.id} {...block} />)
      }
    </Layout>
  );
}

export const query = graphql`
  query IndexPageQuery {
    allContentfulPage {
      edges {
        node {
          blocks {
            ...ContentBlockFields
          }
        }
      }
    }
  }
`;

export default IndexPage;
