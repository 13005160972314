import React from 'react';
import { graphql } from 'gatsby';

import { ServicesBlockProps, ItemBlockProps } from 'models/blocks';
import { Service } from './Service';

import RhombusImg from '../../../images/rhombus.svg';

export const Services: React.FC<ServicesBlockProps> = (props: ServicesBlockProps) => {
  return (
    <div id='services' className='bg-white py-8'>
      <section className='container max-w-5xl mx-auto mx-4'>
        <h1 className='w-full my-2 text-4xl uppercase font-bold leading-tight text-center text-gray font-header'>
          {props.headline}
        </h1>
        {
          props.hasSeparator &&
          <div className='separator-container'>
            <img src={RhombusImg} alt='Rhombus' />
          </div>
        }
        <div className='flex flex-wrap'>
          <div className='w-full p-4'>
            <p className='text-gray-light text-center text-xl'>{props.description!.content}</p>
          </div>
        </div>
        <div className='flex flex-row flex-wrap'>
          {
            props.services && props.services.map(service => {
              const itemProps = service as ItemBlockProps;
              return (
                <Service key={service!.id} {...itemProps}/>
              );
            })
          }
        </div>
      </section>
    </div>
  );
};

// Gatsby preprocesses all GraphQL queries and by including the GraphQL 
// fragment here we guarantee the types are generated
export const blockServicesFields = graphql`
  fragment BlockServicesFields on ContentfulBlockServices {
    headline
    description: copy {
      content: copy
    }
    hasSeparator
    services {
      ...BlockItemFields
    }
  }
`;