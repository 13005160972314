import React from 'react';
import { graphql } from 'gatsby';

import { CopyBlockProps } from 'models/blocks';

import RhombusImg from '../../../images/rhombus.svg';

export const Copy: React.FC<CopyBlockProps> = (props: CopyBlockProps) => {
  return (
    <div className='bg-white py-8'>
      <section className='container max-w-5xl mx-auto mx-4'>
        <h1 className='w-full my-2 text-4xl uppercase font-bold leading-tight text-center text-gray font-header'>
          {props.headline}
        </h1>
        {
          props.hasSeparator &&
          <div className='separator-container'>
            <img src={RhombusImg} alt='Rhombus' />
          </div>
        }
        <div className='flex flex-wrap'>
          <div className='w-full p-4'>
            <p className='text-gray-light text-center text-xl'>{props.copy?.content}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

// Gatsby preprocesses all GraphQL queries and by including the GraphQL 
// fragment here we guarantee the types are generated
export const blockCopyFields = graphql`
  fragment BlockCopyFields on ContentfulBlockCopy {
    headline
    copy {
      content: copy
    }
    hasSeparator
  }
`;